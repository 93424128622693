@import "../../styles/responsive.scss";

.services-title-container {
    padding: 5%;
    height: auto;
    width: 100%;
    text-align: center;
}

.services-title-container h1 {
    text-transform: uppercase;
}



/*ICON TABLE STYLE*/

.icons-table {
    display: flex;
    flex-wrap: wrap;
    /*margin: 0 0 3em 0;*/
    padding: 5% !important;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $tablet) {
        margin-left: 5% !important;
        margin-right: 5% !important;
    }
}

.icons-table-cell {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    list-style: none;
    border: solid 1 px white;
    > h1, > h2, > h3, > h4, > h5, > h6 {
        margin: 0;
        text-align: justify;
    }
}

.icons-table-cell h4 {
    text-transform: uppercase;
    color: black;
}

.icons-table-cell img {
    display: block;
    margin: auto;
    max-height: 100px;
    max-width: 100%;
    padding-top: auto;
    @media screen and (max-width: $tablet) {
        max-height: 50px;
    }
}

.icons-table--2cols > .icons-table-cell  { width: 50%; }
.icons-table--3cols > .icons-table-cell  { width: 33.33%; }
.icons-table--4cols > .icons-table-cell  { width: 25%; }
.icons-table--5cols > .icons-table-cell  { width: 20%; }
.icons-table--6cols > .icons-table-cell  { width: 16.6%; }

.icons-divider {
    width: 100%;
    border-top: 1 px solid rgba(229, 156, 25, 1) !important;
    background-color: rgba(229, 156, 25, 1);
}