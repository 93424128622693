@import "../styles/responsive.scss";

header {
    overflow: hidden;
    position: fixed;
    display: block;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 109px;
}

.navbar {
    overflow: hidden;
    position: fixed;
    display: block;
    width: 100%;
    margin-bottom: 0;
    border-bottom: 1px solid;
    border-color: #477A9F;
    background-color: white;
    @media screen and (max-width: $tablet) {
        height: 77px;
    }
    @media screen and (min-width: $LGdesktop) {
        max-width: 2560px;
    }
    height: 109px;
    padding: 0 !important;
    max-width: 1920px;
}

.navbar-container {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    border-bottom: 1px solid;
    border-color: #477A9F;
    background-color: white;
    @media screen and (max-width: $tablet) {
        position: relative;
    }
}

.navbar-brand {
    position: relative;
    margin-left: 30px;
    margin-top: auto;
    margin-bottom: auto;
    max-width: 176px;
    max-height: 76px;
    @media screen and (max-with: $laptop) {
        max-width: 125px;
    }
    @media screen and (max-width: $tablet) {
        position: relative;
        max-width: 100px;
    }
}

.navbar-brand img{
    cursor: pointer;
    @media screen and (max-with: $laptop) {
        max-width: 125px;
    }
    @media screen and (max-width: $tablet) {
        max-width: 100px;
    }
}

.navbar-toggler-icon {
    color: #477A9F !important;
}

.menu {
    position: fixed;
    width: auto;
    right: 26px;
    margin-right: 0;
    overflow: visible;
    border: none !important;
    box-shadow: none !important;
    /*list-style-type: none;*/
}

/*.dropdown-services:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: relative;
    background: #303030;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}*/

.menu-items {
    width: 160px;
    height: 40px;
    border-width: 0;
    transition: border-width 0.6s linear;
}

.menu-items {
    background: none !important;
}

.menu-items:after {    
  position: absolute;
  left: 0%;
  top: 0%;
  content: '';
  height: 100%;
  transition: all 0.3s linear;
  width: 100%;
  border-bottom: 1px solid rgba($color: #303030, $alpha: 1.0);
  transform: scaleX(0);
}

.menu-items:hover:after {
    transform: scale(1);
}

.menu .item h5 {
    text-align: center;
    margin: auto;
    @media screen and (max-width: $laptop) {
        text-align: left;
    }
}

.menu .item p {
    text-align: justify;
}

.menu .item h5, .menu .item p {
    text-transform: uppercase;
    color: #303030;
    padding: auto;
}

a:hover {
    text-decoration: none !important;
}

.dropdown-items {
    width: 250px;
}

.dropdown-divider {
    margin: 0 !important;
}

.nav-item {
    text-align: center;
}

.nav-item a:hover {
    color: #303030;
}

.nav-link {
    background-color: white;
    width: 160px;
    height: 40px;
    color: #303030;
    text-decoration: none;
}

.nav-link p {
    text-transform: uppercase;
}

.dropdown-services {
    text-transform: uppercase;
    z-index: 100;
}

.menu-container {
    float: right;
    width: 640px;
    height: 109px;
    top: 0px;
    font-family: 'Arsenal', sans-serif; 
    display: flex;      
    align-items: center;  
    justify-content: space-between;
    margin-right: 24px;
    padding-right: 0;
}


/*@media screen and (max-width: $tablet) {
    .menu-container {
        display: none;
    }
}*/

@media screen and (min-width: $laptop) {
    .navbar-expand-lg .navbar-collapse {
        max-width: 2560px !important;
        display: flex !important;
        flex-basis: auto;
    }
}

.menu-button {
    background-color: white;
    width: 160px;
    height: 40px;
    color: #303030;
    text-decoration: none;
}

.nav-item:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: relative;
    background: #303030;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.nav-item:hover:after {
    width: 100%; 
    left: 0; 
}

.menu-button p {
    text-align: center;
}