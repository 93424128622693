.aphorism-container {
    padding: 5%;
    height: auto;
    width: 100%;
    text-align: center;
    background-color: #E59C19;
}

.aphorism {
    color: white;
}

.author {
    position: relative;
    text-align: right;
    color: white;
}

.aphorism-container hr {
    width: 40%;
    border-top: 1px solid white !important;
}