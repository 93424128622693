@import "../styles/responsive.scss";

/*GLOBAL*/
.home-row {
    left: 0;
    position: relative;
    width: 100%;
    margin: 0%;
}

.home {
    width: 100%;
    margin: auto;
    @media screen and (max-width: $tablet) {
        max-width: 768px !important;        
    }
    @media screen and (max-width: $mobile) {
        max-width: 320px !important;        
    }
    /*margin: 0 !important;
    padding: 0 !important;*/
}


.home h5 {
    margin-top: 20% !important;
    color: white;
    font-size: 48px;
    text-transform: uppercase;
}

.home p {
    margin-top: 30px;
    color: white;
    font-size: 32px;
}

/*.home h5, p {
    margin-left: 10%;
}*/

@media screen and (max-width: $LGdesktop) {
    .home h5 {
        font-size: 36px;
    }
    .home p {
        font-size: 28px;
    } 
}

@media screen and (max-width: $desktop) {
    .home h5 {
        font-size: 32px;
    }
    .home p {
        font-size: 24px;
    } 
}

@media screen and (max-width: $tablet) {
    .home h5 {
        font-size: 28px;
    }
    .home p {
        font-size: 20px;
    }
}

/*SEZIONE TITOLO*/
.title-section {
    display: block;
    width: 100%;
    max-width: 1920px !important;
    top: 110px;
    padding: 0 !important;
}

.title-container {
    position: relative;
    width: 100%;
    height: 972px;
    margin: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.title {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
}

.title h1, h4 {
    width: 50%;
    text-transform: uppercase;
    color: white;
    text-align: center;
}

.title h1 {
    font-size: 90px;
    margin-top: 0;
    margin-bottom: 30px;
    @media screen and (max-width: $tablet) {
        font-size: 40px;
    }
    @media screen and (max-width: $mobile) {
        font-size: 20px;
    }
}

.title h4 {
    font-size: 30px;
    margin: 0;
    font-size: 25px;
    @media screen and (max-width: $tablet) {
        font-size: 12px;
    }
    @media screen and (max-width: $mobile) {
        font-size: 12px;
    }
}

/*SEZIONE SERVIZI - TESTO A SINISTRA*/
.left-text-section {
    display: block;
    width: 100%;
    max-width: 1920px !important;
    padding: 0 !important;
}

.left-text-container {
    display: flex;
    position: relative;
    float: left;
    width: 100%;
    height: 972px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.left-text-description { 
    float: left;
    padding-left: 10% !important;
    padding-right: 0 !important;
}

/*SEZIONE SERVIZI - TESTO A DESTRA*/
.right-text-section {
    display: block;
    width: 100%;
    max-width: 1920px !important;
    padding: 0 !important;
}

.right-text-container {
    display: flex;
    position: relative;
    float: left;
    width: 100%;
    height: 972px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.right-text-overlay {    
    width: 100%;
    height: 972px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: black;
    opacity: 1;
}

.right-text-description { 
    float: right;
    @media screen and (max-width: $tablet) {
        padding-left: 10% !important;
    }
}

/*SEZIONE CONTATTACI*/
.contact-us-section {
    display: block;
    width: 100%;
    max-width: 1920px !important;
    padding: 0 !important;
    background-color: white;
}

.contact-us-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
}

/*.contact-us-container h1 {
    text-transform: uppercase;
    text-align: center;
    margin-top: 70px !important;
}*/

.btn-contact {
    position: relative;
    width: 300px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}