.contacts-title-container {
    padding: 5%;
    height: auto;
    width: 100%;
    text-align: center;
    background-color: #E59C19;
}

.contacts-title-container h1 {
    color: white;
    text-transform: uppercase;
}

.contacts-introduction {
    display: flex;
    flex-direction: row;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
}

.contacts-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 5%;
}

.contacts-text-hr {
    width: 40%;
    border-top: 1px solid #E59C19 !important;
    height: 1px;
    align-items: center;
}

.contacts-aphorism-container {
    padding: 5%;
    height: auto;
    width: 100%;
    text-align: center;
    border: 1px solid #E59C19;
}


.contacts-aphorism {
    color: #303030;
}

.contacts-aphorism-author {
    position: relative;
    text-align: right;
    color: #303030;
}

.contacts-aphorism-container hr {
    width: 40%;
    border-top: 1px solid #E59C19 !important;
}

.address-section {
    margin-top: 30px;
}

.address {
    display: flex;
    flex-direction: row;
}

.address-text-container {
    display: flex;
    flex-direction: column;
    margin-top: 0;
}

.address-text-title h6{
    text-transform: uppercase;
}

.address-text {
    top: 0;
    margin-top: 0;
}

.address-hr {
    border-top: 1px solid #E59C19 !important;
    height: 1px;
    width: 150px;
    margin-left: 0;
}

.google-map {
    width: 100%;
    height: 400px;
    border: 0;
}