@import "../styles/responsive.scss";

.about-text-container {
    display: block;
    margin: auto;
    padding: 5% !important;
    @media screen and (max-width: $tablet){
        margin-left: 5% !important;
        margin-right: 5% !important;
    }
}

.about-text-container p {
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width:$tablet) {
    .about-text-container {
        padding: 0;
    }
    .about-text-container p {
        text-align: center;
        padding: 2% !important;
        margin-bottom: 0;
    }

}

.rete-hr {
    border-top: 1px solid #E59C19 !important;
    height: 1px;
    width: 300px;
    text-align: center;
}