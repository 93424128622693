@import "../styles/responsive.scss";

.burger-menu {
    display: none;
    position: fixed;
    right: 0;
    cursor: pointer;
    top: 20px;
    margin-bottom: auto;
    margin-right: 30px;
    @media screen and (max-width: $laptop) {
        display: inline-block;
    }
  }

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #477A9F;
    margin: 6px 0;
    transition: 0.4s;
    }
    
.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

.hidden {
    display: none !important;
}

.icon {
    display: none !important;
}