@import "../styles/responsive.scss";

*{
    box-sizing: border-box;
}

.footer-section {
    margin-top: 1rem;
    padding: 1rem;
    background-color: rgba($color: #707070, $alpha: 0.19);
    min-height: 374px;
    bottom: 0;
    left: 0;
    width: 100%;
}

.footer-container {
    display: flexbox;
}

.footer-column {
    display: flex;
    flex-direction: column;
}
.footer-column h6 {
    text-transform: uppercase;
}

.logo-container {
    background-color: transparent;
    display: block;
    position: relative;
    float: left;
    margin-left: 15px;
}

img {
    left: 0;
    margin: 0;
}

.text-container {
    padding-left: 15px;
    @media screen and (max-width: $tablet) {
        padding-top: 10%;
    }
}

/*.center {
    margin-top: 10%;
}*/