@import "../../styles/responsive.scss";

.services-title-container {
    padding: 5%;
    height: auto;
    width: 100%;
    text-align: center;
}

.services-title-container h1 {
    text-transform: uppercase;
}

.text-container h3 {
    text-align: center;
    text-transform: uppercase;
}

.services-divider {
    width: 40%;
    border-top: 1 px solid rgba(229, 156, 25, 1) !important;
    background-color: rgba(229, 156, 25, 1);
}

.services-container-left .text-container, .services-container-right .text-container {
    padding: 5% !important;
    @media screen and (max-width: $tablet) {
        margin-left: 5% !important;
        margin-right: 5% !important;
    }
}

.image-container {    
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 !important;
}

.image-container img {
    max-width: 100%;
    height: auto;
}

@media screen and (min-width: $tablet) {
    .image-container img {
        max-width: 100%;
        height: auto;
    }
    .services-container-left {
        width: 100%;
        flex-direction: row;
    }
    
    .services-container-right {
        width: 100%;
        flex-direction: row-reverse;
    }
}

@media screen and (max-width: $tablet) {    
    .image-container {
        height: max-content;
    }
}
