@import "../styles/responsive.scss";

.worker-section {
    display: flex;
}

.worker-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 50px;
}

.about-name h6 {
    text-transform: uppercase;
}

.worker-hr {
    border-top: 1px solid #E59C19 !important;
    height: 1px;
    width: 300px;
    margin-left: 0;
}

.about-personal-container-left {
    display: flex;
    margin-top: 30px;
    flex-direction: row;
}

.about-personal-container-right {
    display: flex;
    margin-top: 30px;
    flex-direction: row-reverse;
    p {
        float: left;
        margin-left: 0;
        text-align: justify;
    }
}

.about-personal-desc {
    display: flex;
    width: auto;
    padding: 10px;
}

.about-personal-img {
    max-width: 260px;
    height: auto;
}

@media screen and (max-width: $tablet) {
    .about-personal-container-left {
        flex-direction: column;
    }
    .about-personal-container-right {
        flex-direction: column;
    }
    .about-personal-img {
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
    .about-name h6 {
        text-align: center;
    }
    .worker-hr {
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
}
