.contact-us-section {
    display: block;
    width: 100%;
    max-width: 1920px !important;
    padding: 0 !important;
    background-color: white;
}

.contact-us-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
}

.contact-us-container h1 {
    text-transform: uppercase;
    text-align: center;
    margin-top: 0.3em !important;
}

.btn-contact {
    position: relative;
    width: 300px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}